<template>
    <div v-html="terms.content.replace(/\n/g, '<br>')" />
</template>
<script>
import api from "@/api";
export default {
    data(){
        return {
            terms: null
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { terms } = await api.v1.terms.get({ code: this.$route.params.code });
                this.terms = terms;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    }

}
</script>
